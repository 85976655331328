import React from "react"
import PropTypes from "prop-types"
import ReactMarkdown from "react-markdown"

const formatWikipedia = string => {
  return string.replace(/ *\([^)]*\) */g, " ").replace(/ *\[[^)]*\] */g, " ")
}

const format = (bold, string) => {
  // For each word in bold,
  bold.forEach(word => {
    string = string.replace(word, `**${word}**`)
  })
  return string
}

export const Wikipedia = ({ extract, bold }) => {
  return extract
    .split("\n")
    .slice(0, 2)
    .map((extract, idx) => (
      <p key={idx}>
        <ReactMarkdown>
          {format(bold || [], formatWikipedia(extract))}
        </ReactMarkdown>
      </p>
    ))
}

Wikipedia.propTypes = {
  extract: PropTypes.string.isRequired,
}
